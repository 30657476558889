.section {
  padding: 7rem 3rem 10rem 3rem;

  &--coding {
    background-color: var(--dark-teal);
    background-color: #005763;
    color: white;
  }

  &__lining {
    max-width: 60rem;
    margin: 0 auto;
  }

  &__heading {
    text-align: center;
    font-weight: bold;

    &--primary {
      font-size: 1.5em;
    }
  }

  &__image {
    display: block;

    * {
      border-radius: 12.5rem;
      width: 25rem;
      margin: 0 auto;
    }
  }
}
