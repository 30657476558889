body {
  font-family: 'Inconsolata', monospace;
  color: var(--dark-grey);
  color: #2a2a2a;
  line-height: 1.4;
  min-width: 32rem;
}

a {
  font-weight: bold;
  transition: color .15s;
}
