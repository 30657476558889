.information {
  display: flex;
  justify-content: center;
  margin: auto 0;

  svg {
    fill: none;
    stroke: var(--dark-grey);
    stroke: #2a2a2a;
    stroke-width: 1.3;
    margin-bottom: -.5rem;
    transition-property: stroke, transform;
    transition-duration: .15s;
    width: 2.4rem;
    height: 2.4rem;
  }

  a:hover svg {
    transform: scale(1.08) rotate(-3deg);
  }

  &__item {
    padding: .5rem 0;
  }
}
