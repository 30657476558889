.mt--3 { margin-top: 3rem; }
.mt--5 { margin-top: 5rem; }
.mt--10 { margin-top: 10rem; }

.mb--3 { margin-bottom: 3rem; }
.mb--5 { margin-bottom: 5rem; }
.mb--10 { margin-bottom: 10rem; }

.text--center { text-align: center;  }
.text--80 { font-size: .8em; }

.flex { display: flex; }

.flow > * + * { margin-top: var(--flow-space, 2rem); }
